import create from 'zustand'
import produce from 'immer'
import { createTrackedSelector } from 'react-tracked'
import {
  getOrderDetailApiRequest,
  getOrderGetOrderStatusApiRequest,
  getV1ChainStarGetOpenStationInitApiRequest,
} from '@/apis/lightning-open-station'

import { YapiGetV1ChainStarGetOpenStationInitData } from '@/typings/yapi/ChainStarGetOpenStationInitV1GetApi'
import { YapiGetOrderDetailData } from '@/typings/yapi/OrderDetailGetApi'
import { YapiGetOrderGetOrderStatusData } from '@/typings/yapi/OrderGetOrderStatusGetApi'

type IStore = ReturnType<typeof getLightningOpenStation>

function getLightningOpenStation(set, get) {
  const state = {
    openStationNavEnum: 0,
    setOpenStationNavEnum(number: number) {
      set(
        produce((draft: IStore) => {
          draft.openStationNavEnum = number
        })
      )
    },
    payWin: true,
    openWin: true,
    gopay: true,
    setPayWin(isDistab: boolean) {
      set(
        produce((draft: IStore) => {
          draft.payWin = isDistab
        })
      )
    },
    setOpenWin(isDistab) {
      set(
        produce((draft: IStore) => {
          draft.openWin = isDistab
        })
      )
    },
    setGoPay(isDistab) {
      set(
        produce((draft: IStore) => {
          draft.gopay = isDistab
        })
      )
    },
    orderStatus: undefined as YapiGetOrderGetOrderStatusData | undefined,
    getOrderStatusData: async () => {
      // const { isOk, data } = await getOrderGetOrderStatusApiRequest({})
      // if (isOk) {
      //   set(
      //     produce((draft: IStore) => {
      //       if (data) {
      //         draft.orderStatus = data
      //       }
      //     })
      //   )
      // }
      // return data
    },

    // 获取一个订单详情
    oneOrderDetail: undefined as YapiGetOrderDetailData | undefined,
    getOrderDetai: async (orderNo: string) => {
      const { isOk, data } = await getOrderDetailApiRequest({ orderNo })
      if (isOk) {
        set(
          produce((draft: IStore) => {
            if (data) {
              draft.oneOrderDetail = data
            }
          })
        )
      }
    },
    openStationInit: undefined as YapiGetV1ChainStarGetOpenStationInitData | undefined,
    getOpenStationInit: async () => {
      const { isOk, data } = await getV1ChainStarGetOpenStationInitApiRequest({})
      if (isOk) {
        set(
          produce((draft: IStore) => {
            if (data) {
              draft.openStationInit = data
            }
          })
        )
      }
    },
  }
  return state
}
const baseLightningOpenStation = create(getLightningOpenStation)
const useLightningOpenStation = createTrackedSelector(baseLightningOpenStation)

export { useLightningOpenStation, baseLightningOpenStation }
